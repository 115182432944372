export const resetScroll = (): void => {
    const storageKey = 'pdfjs.history';
    const historyJson = window.localStorage.getItem(storageKey);

    if (historyJson) {
        const history = JSON.parse(historyJson);

        history.files.forEach((file: any) => {
            file.page = 0;
            file.scrollTop = 0;
        });

        window.localStorage.setItem(
            storageKey,
            JSON.stringify(history)
        );
    }
};